@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
    font-family: 'Inter', sans-serif;
}

.box {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #4c62ef;
}

#nprogress .bar {
    background: #4c62ef !important;
    height: 5px !important;
}

#nprogress .spinner-icon {
    border-top-color: #4c62ef !important;
    border-left-color: #4c62ef !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px #14151f, 0 0 5px #14151f !important;
}

.swiper {
    display: grid !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #0004ff;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #008cff;
}